<template>
   <el-container>
      <el-row>
         <el-col :xs="2" :sm="2" :md="5" :lg="5" class="burger left_column">
            <el-aside style="width: initial; height: 100%;">
               <el-image :src="require('@/assets/logo.svg')" class="logo hidden-sm-and-down"></el-image>
               <Burger />
            </el-aside>
         </el-col>
         <el-col :xs="22" :sm="22" :md="19" :lg="19" class="right_column">
               <el-main>
                  <el-row :gutter="20">
                     <el-col :span="24">
                        <el-space>
                        <h2 class="title">Список курсов</h2>
                        <el-button type="text" @click="onLogout()">
                           <el-image :src="require('@/assets/exit.svg')" class="exit"></el-image>
                        </el-button>
                     </el-space>
                     </el-col>
                  </el-row>
                  <el-row :gutter="20">
                     <el-col v-for="course in courses" :key="course.id" :span="12" @click="getCourse(course.id)" :xs="24" :md="12">
                        <el-card shadow="hover" class="courses_card">
                           <el-space>
                              <h3>{{ course.title }}</h3>
                              <el-button>
                                 <el-image :src="require('@/assets/batterfly_up.svg')" class="courses_img" v-if="course.id === 3"></el-image>
                                 <el-image :src="require('@/assets/batterfly.svg')" class="courses_img" v-else></el-image>
                              </el-button>
                           </el-space>
                              <el-button>
                                 <el-image :src="require('@/assets/in_develope.svg')" class="courses_button" v-if="course.id === 3"></el-image>
                                 <el-image :src="require('@/assets/get_course.svg')" class="courses_button" v-else></el-image>
                              </el-button>
                        </el-card>
                     </el-col>
                  </el-row>
               </el-main>
               <el-footer>
                  <span>Полититка конфедициальности</span><br>
                  <span>let.tobe.happy@gmail.com</span>
               </el-footer>
         </el-col>
      </el-row>
   </el-container>
</template>

<script>

import Burger from '../components/Burger.vue';
import 'element-plus/lib/theme-chalk/display.css';
import axiosInstance from '../services/AxiosTokenInstance';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
   LOGOUT_ACTION,
   GET_USER_TOKEN_GETTER,
   LOADING_SPINNER_SHOW_MUTATION,
} from '../store/storeconstants';

export default {
    data() {
        return {
            courses: [],
        };
    },
    computed: {
        ...mapGetters('auth', {
            token: GET_USER_TOKEN_GETTER,
        }),
    },
    components: {
      Burger,
    },
    mounted() {
        this.showLoading(true);
        axiosInstance
            .get(`https://betterlife-school.ru/app/api/courses`)
            .then((response) => {
                this.getCourses(response.data);
                this.showLoading(false);
            })
            .catch(() => {
                this.showLoading(false);
            });
    },
    methods: {
         ...mapActions('auth', {
            logout: LOGOUT_ACTION,
        }),
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        getCourses(courses) {
            for (let key in courses) {
               this.courses.push({ ...courses[key] });
            }
        },
         onLogout() {
            this.logout();
            this.$router.replace('/login');
        },
        getCourse(id) {
           if(id === 3) return;
            this.$router.push({ path: `/course/${id}` })
        },
    },
};
</script>

<style>
   @media only screen and (min-width: 992px) {
      .bm-menu {
         min-width: 20%;
         margin-top: 13%;
      }
      .bm-burger-button {
         display: none;
      }
      .bm-cross-button {
         display: none;
      }
   }
   .courses_card .el-card {
     background-color: #F4F4F6;
     border-radius: 15px;
   }
</style>

<style scoped>
   .logo {
      position: fixed;
      padding: 10px;
      margin-bottom: 20px;
      width: 15%;
      left: 2%;
      top: 5%;
   }
   .el-main {
      height: 100%;
   }
   .el-space {
      display: flex;
      justify-content: space-between;
   }
   .el-container {
      background: #ffffff;
      }
   .el-header, .el-footer {
      background-color: #B3C0D1;
      color: #333;
      text-align: center;
      line-height: 60px;
   }
  body > .el-container {
    margin-bottom: 40px;
  }
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  .el-card {
     background-color: #F4F4F6!important;
  }
  .courses_card .el-button {
     border: none;
  }
  .courses_card h3 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      padding-left: 20px;
  }
  .courses_card .el-button,
  .courses_card .el-button:hover {
     background-color: #F4F4F6;
  }
</style>
